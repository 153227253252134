import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = { key: 5 }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { key: 7 }
const _hoisted_8 = { key: 8 }
const _hoisted_9 = { key: 9 }
const _hoisted_10 = { key: 10 }
const _hoisted_11 = { key: 11 }
const _hoisted_12 = { key: 12 }
const _hoisted_13 = { key: 13 }
const _hoisted_14 = { key: 14 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductivityReport = _resolveComponent("ProductivityReport")!
  const _component_ViewOrderDriverReport = _resolveComponent("ViewOrderDriverReport")!
  const _component_OrdersByStatusReport = _resolveComponent("OrdersByStatusReport")!
  const _component_ProductivityPerDay = _resolveComponent("ProductivityPerDay")!
  const _component_OrdersByStatusMonthly = _resolveComponent("OrdersByStatusMonthly")!
  const _component_VIewOrdersTiming = _resolveComponent("VIewOrdersTiming")!
  const _component_ViewDriversTimingPerDay = _resolveComponent("ViewDriversTimingPerDay")!
  const _component_ViewDriversTimingPerMonth = _resolveComponent("ViewDriversTimingPerMonth")!
  const _component_DriverActivityReport = _resolveComponent("DriverActivityReport")!
  const _component_DriverProductivityReport = _resolveComponent("DriverProductivityReport")!
  const _component_DriverDeliveryReport = _resolveComponent("DriverDeliveryReport")!
  const _component_DarkStoreDeliveryMetricsReport = _resolveComponent("DarkStoreDeliveryMetricsReport")!
  const _component_ZoneDeliveryMetricsReport = _resolveComponent("ZoneDeliveryMetricsReport")!
  const _component_DriverPerformance = _resolveComponent("DriverPerformance")!
  const _component_HourlyDeliveryTimeAnalysis = _resolveComponent("HourlyDeliveryTimeAnalysis")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.reportType?.name === 'view_drivers_productivity_per_month')
      ? (_openBlock(), _createBlock(_component_ProductivityReport, {
          key: 0,
          reportType: _ctx.reportType
        }, null, 8, ["reportType"]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_orders_and_drivers')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ViewOrderDriverReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_orders_by_status_per_day')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_OrdersByStatusReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_drivers_productivity_per_day')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProductivityPerDay, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_orders_by_status_per_month')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_OrdersByStatusMonthly, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_orders_timing')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_VIewOrdersTiming, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_drivers_timing_per_day')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_ViewDriversTimingPerDay, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'view_drivers_timing_per_month')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_ViewDriversTimingPerMonth, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Drivers_activity_per_darkstore_per_shift')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_DriverActivityReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Drivers_productivity')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_DriverProductivityReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Driver_delivery')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_DriverDeliveryReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Darkstore_delivery_metrics')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_DarkStoreDeliveryMetricsReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Zone_delivery_metrics')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_ZoneDeliveryMetricsReport, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Driver_performance')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_DriverPerformance, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reportType?.name === 'Hourly_delivery_time_analysis')
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_HourlyDeliveryTimeAnalysis, { reportType: _ctx.reportType }, null, 8, ["reportType"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}